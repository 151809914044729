import i18n, { type InitOptions } from "i18next"
import ChainedBackend from "i18next-chained-backend"
import LocalStorageBackend from "i18next-localstorage-backend"
import HttpBackend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"
import env from "./env"

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false
  }
}

export const localeCountry = env.locale.substr(3, 2).toLowerCase()
export const sizeUnit = env.locale === "en-US" ? "in" : "mm"

export const options: InitOptions = {
  fallbackLng: [],
  debug: false,
  returnObjects: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
    prefix: "%{",
    suffix: "}",
  },
}

let initPromise: Promise<any> = new Promise(resolve => resolve(null))

if (!i18n.isInitialized && !import.meta.env.SSR) {
  // dynamic loading and caching to localstorage keyed by commit
  initPromise = i18n
    .use(initReactI18next)
    .use(ChainedBackend)
    .init({
      ...options,
      lng: env.locale,
      load: "currentOnly",
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            defaultVersion: env.VERSION,
          },
          {
            loadPath: "/locale/%{lng}.json",
          },
        ],
      },
    })
}

const streetNrCountries = ["DE", "AT", "BE", "NL"]

export const streetNrRequired = (forCountry?: string | null): boolean => {
  forCountry = forCountry || localeCountry

  return streetNrCountries.includes(forCountry.toUpperCase())
}

export const showStreet2 = (country: string): boolean => {
  return ["GB", "IE", "FR", "US", "EE", "ES", "FI"].includes(country)
}

export const stateRequired = (country: string): boolean => {
  return ["AU", "US"].includes(country)
}

export const multilingual = (urlLocale?: string) => {
  urlLocale = urlLocale || env.locale
  return urlLocale.includes("BE") || urlLocale.includes("CH")
}

export const url = (path: string, urlLocale?: string) => {
  if (multilingual(urlLocale)) {
    return `/${(urlLocale || env.locale).substr(0, 2)}${path}`
  }

  return path
}

export const locale = env.locale
export const languageSegment = env.languageSegment
export const i18nPromise = initPromise

export const availableLocales = [
  "cs-CZ",
  "da-DK",
  "de-AT",
  "de-CH",
  "de-DE",
  "en-AU",
  "en-GB",
  "en-IE",
  "en-NZ",
  "en-US",
  "es-ES",
  "et-EE",
  "fi-FI",
  "fr-BE",
  "fr-CH",
  "fr-FR",
  "it-CH",
  "it-IT",
  "nb-NO",
  "nl-BE",
  "nl-NL",
  "pl-PL",
  "sv-SE",
] as const

export default i18n
